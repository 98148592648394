$footerColorFontHover: rgb(56, 56, 56);

.footer {
    width: 100%;
    padding-top: 20px;
    background-color: rgb(250, 250, 250);
    border-top: 1px solid rgba(0, 0, 0);
    bottom: 0;
}

.footer h4 {
    color: rgb(0, 0, 0);
}

.footer .footer-list li a {
    color: rgb(0, 0, 0);
    padding-top: 8px;
    display: inline-block;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
}

.footer .footer-list li a:hover {
    color: $footerColorFontHover;
}

.footer-alt{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0);
    color: rgb(255, 255, 255);

    @media only screen and (max-device-width: 600px) and (orientation: portrait) {
        height: 50px;
        
    }
}

.copy-rights{
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    font-weight: 100;
    font-size: .9rem;
}