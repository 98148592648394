.pro-mode-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;

    .pro-mode-title{
        max-width: 850px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 5vh 0px;

        & img{
            justify-content: center;
            width: 390px;
            min-width:55%;
        }

        & .text-content{
            width: 30%;
        }

        & .text-content > h2{
            font-size: 3rem;
            letter-spacing: -.1rem;
            font-weight: 700;
            margin-bottom: 30px;
            text-align: center;
        }
        @media only screen and (max-device-width: 600px) {
            flex-direction: column;
            .text-content{
                margin-top: 20px;
                width: 80%;
            }
        }
        @media only screen and (max-width: 880px){
            flex-direction: column;
            .text-content{
                margin-top: 20px;
                width: 50%;
            }
        }
    }

    & .pro-options-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        & .totales-container{
            width: 80%;

            & h4{
                text-align: center;
            }
        }

        & .totales-input{    
            width: 20%;
            margin: auto;

            @media only screen and (max-device-width: 600px) {
                width: 70% !important;
            }
            @media only screen and (max-width: 880px){
                width: 30%;
            }
        }

        & .macros-container{
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}



input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}