.modal__backdrop {
	background: rgba(0, 0, 0, 0.769);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 99;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & .modal__container {
        background: #fff;
        border-radius: 5px;
        margin: 50px auto;
        padding: 25px;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .modal__title{
            margin-bottom: 20px;
            text-align: center;
        }

        @media only screen and (max-device-width: 600px) {
            width: 80%;
        }
    }
}


