.front-page-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    padding-top: 15%;

    & .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;

        & h3{
            font-size: 1.2rem;
        }
    }
}

.front-page-title{
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    padding: 3vh 0px;
    justify-content: space-around;
    align-items: center;
    color: black;
}

.front-page-title > h2 {
    font-size: 3.5rem;
    letter-spacing: -.1rem;
    font-weight: 700;
}

.front-page-title > h5 {
    margin-bottom: 20px;
}

.front-page-description{
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    padding-top: 12vh;
    justify-content: space-around;
    align-items: center;
    color: black;
    border-top: 5px solid rgba(0, 0, 0);
    margin-top: 80px;
}

.front-page-description > h2 {
    font-size: 3.5rem;
    letter-spacing: -.1rem;
    font-weight: 700;
    margin-bottom: 50px;
}

.front-page-description > h5 {
    width: 60%;
    text-align: center;
}

.front-page-try{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.front-page-try > h5 {
    margin-top: 20px ;
    width: 60%;
    text-align: center;
    color: #2997ff;
}

.button-font-page{
    padding: .3vw 2vw;
    color: black;
    border: 1px solid black;
    border-radius: 8px;
    transition: 0.5s;

    &:hover {
        background-color: black;
        color: rgb(255, 255, 255);
      }
}

.front-page-img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}

.front-page-img img{
    width: 35%;
    min-width: 450px;
}

.link{
    text-decoration: none;
}

.front-page-try:hover{
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline;
    color: #2997ff;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-device-width: 600px) and (orientation: portrait) {
    .front-page-title{
        padding: 8vh 0px;
    }
    .front-page-title > h2{
        font-size: 5rem;
    }
    .front-page-title > h5{
        font-size: 1.5rem;
    }
    .front-page-description > h2{
        font-size: 5rem;
        margin-bottom: 20px;
    }
    .front-page-description > h5{
        font-size: 1.5rem;
        width: 80%;
    }

    .front-page-try > h5 {
        width: 80%;
    }
    
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-device-width: 600px) and (max-device-width: 768px) and (orientation: portrait){
    
    .front-page-title > h2{
        font-size: 2em;
    }
    .front-page-title > h5{
        font-size: 1em;
    }
    .front-page-description > h2{
        font-size: 2em;
    }
    .front-page-description > h5{
        font-size: 1em;
    }
}
/* Medium devices (768px and up) */
@media only screen and (min-device-width: 768px) and (max-device-width: 992px) and (orientation: portrait){
    .front-page-title{
        padding: 5vh 0px;
    }
    .front-page-title > h2{
        font-size: 3rem;
    }
    .front-page-title > h5{
        font-size: 1.5rem;
    }
    .front-page-description > h2{
        font-size: 3rem;
    }
    .front-page-description > h5{
        font-size: 1.5rem;
    }
}
/* Large devices (laptops, 992px and up) */
@media only screen and (min-device-width: 992px) and (max-device-width : 1200px) and (orientation: portrait){
    
}
/* Extra large devices (large laptops, 1200px and up) */
@media only screen and (min-devivce-width: 1200px) and (orientation: portrait){
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-device-width: 600px) and (max-device-width: 768px) and (orientation: landscape){
    .front-page-title{
        height: 40%;
        padding: 5vh 0px;
    }
    .front-page-title > h2{
        font-size: .9rem;
    }
    .front-page-title > h5{
        font-size: .5rem;
    }
    .button-font-page{
        padding: .3vw 2vw;
        font-size: .8rem;
    }
    .front-page-description > h2{
        font-size: .9rem;
        margin-bottom: 20px;
    }
    .front-page-description > h5{
        font-size: .5rem;
    }
}
/* Medium devices (768px and up) */
@media only screen and (min-device-width: 768px) and (max-device-width: 992px) and (orientation: landscape){
    .front-page-title{
        height: 40%;
        padding: 5vh 0px;
    }
    .front-page-title > h2{
        font-size: 2rem;
    }
    .front-page-title > h5{
        font-size: 1rem;
    }
    .button-font-page{
        padding: .3vw 2vw;
        font-size: .8rem;
    }
    .front-page-description > h2{
        font-size: 2rem;
    }
    .front-page-description > h5{
        font-size: 1rem;
    }
}
/* Large devices (laptops, 992px and up) */
@media only screen and (min-device-width: 992px) and (max-device-width : 1200px) and (orientation: landscape){
    
}
/* Extra large devices (large laptops, 1200px and up) */
@media only screen and (min-devivce-width: 1200px) and (orientation: landscape){
    
}

// DESKTOP
@media only screen and (min-width: 50px) and (max-width: 768px){
    .front-page-title > h2{
        font-size: 6vw;
    }
    .front-page-title > h5{
        font-size: 3vw;
    }
    .front-page-description > h2{
        font-size: 6vw;
    }
    .front-page-description > h5{
        font-size: 3vw;
    }
}
/* Medium devices (768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px){
   
}
/* Large devices (desktop, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px){
   
}
/* Extra large devices (desktops,
1200px and up) */
@media only screen and (min-width: 1200px){
    
}
