.sign-in-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h2 {
    text-align: center;
    letter-spacing: -0.1rem;
    font-weight: 700;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
  }
  .anchor-register{
    text-decoration: underline;
    color: green;
  }

  @media screen and (max-width: 600px) {
    padding: 0 5%;
  }
  @media screen and (max-width: 1000px) and (orientation: landscape) {
    margin-top: 130px;
    padding-bottom: 35px;
  }
}