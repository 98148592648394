.terms-and-conditions {
    display: flex;
    flex-direction: column;
    text-align: justify;
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
  
    h1 {
      text-align: center;
      margin-bottom: 20px;
    }
  
    h2 {
      margin-top: 20px;
    }
  
    p {
      margin: 10px 0;
      line-height: 1.6;
    }
  
    ul {
      margin: 10px 0;
      padding-left: 20px;
  
      li {
        margin: 5px 0;
      }
    }

    #volver{
      width: 160px;
      margin: 20px auto 0px auto;
    }
  }
  