.visuallyhidden{
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
  }
  html, body, #root {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}
