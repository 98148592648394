.user-page{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 130px 0;

    .user-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 50%;

        h2 {
          margin-bottom: 20px;
          text-align: center;
        }

        form{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 100%;

          .name-label, .email-label, .email-verified-label{
            width: 80%;
            margin-bottom: 20px;
          }

          .name-label{
            label{
              margin-right: 10px;
              width: 15%;
            }
            input{
              width: 70%;
            }
          }
          .email-label{
            label{
              margin-right: 10px;
              width: 15%;
            }
            input{
              width: 70%;
            }
          }

          .email-verified-label{
            margin-bottom: 50px;
            label{
              margin-right: 10px;
              width: 25%;
            }
            input{
              width: 60%;
            }
          }
        } 
    }  
}
