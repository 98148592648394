:root {
    --primary-color: #000000;
    --background-color: #ffffff;
    --border-color: #a0a0a0;
  }

.nav-bar{
    width: 100dvw;
    height: 65px;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--background-color);
    z-index: 4;

    a{
        text-decoration: none;
        color: var(--primary-color);
    }

    a:hover{
        color: var(--primary-color);
    }
}

.logo-container{
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;

    h3{
        font-size: 1.2rem;
        margin: 0;
    }
}

.logo-container img{
    width: 50px;
}

.nav-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 135px;

    @media only screen and (max-device-width: 600px) {
      width: 135px;
   }
}

.nav-container-logo{
    display: flex;
    align-items: b;
    flex-direction: row;
    justify-content: space-between;
    width: 235px;
    padding-left: 20px;
    margin-right: 8px;

    @media only screen and (max-device-width: 600px) {
      max-width: 50%;
   }
}

.user-icon {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }