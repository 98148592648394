:root {
    --primary-color: #000;
    --secondary-color: #2997ff;
    --background-color: #fff;
    --black-background-color: #000;
    --white-color: #fff;
    --text-color-light: #8a8a8a;
    --font-size-large: 3rem;
    --font-size-medium: 2.5rem;
    --font-size-small: 1rem;
    --border-radius: 25px;
  }
  
  .option-menu-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 15vh;
    flex-direction: column;
  
    .black-background {
      background-color: var(--black-background-color);
      color: var(--white-color);
    }
  }
  
  .options-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15vh;
  }
  
  .options-container-title {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
    text-align: center;
  
    h2 {
      font-size: var(--font-size-large);
      letter-spacing: -0.1rem;
      font-weight: 700;
      margin-bottom: 30px;
    }
  
    h5 {
      font-weight: 400;
      color: var(--text-color-light);
    }

    @media (max-width: 1335px) {
      width: 50%;
    }
  
    @media (max-width: 917px) {
      width: 70%;
    }
  
    @media (max-width: 760px) {
      width: 80%;
    }
  
    @media (max-width: 655px) {
      width: 90%;
    }
  
    @media (max-width: 600px) {
      width: 80%;
      h2 {
        font-size: var(--font-size-medium);
      }
    }
  }
  
  .option-information {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  
    Button {
      background: var(--secondary-color);
      border-radius: var(--border-radius);
    }
  
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
  
  .more-information, .coming-soon {
    color: var(--secondary-color);
    margin-top: 10px;
  
    &:hover {
      text-decoration: underline;
    }
  }
  
  .options-container-image {
    padding: 3% 0;
    width: 100%;
    text-align: center;
  
    img {
      width: 60%;
      
    }

    @media (max-width: 1300px) {
      img {
        width: 70%;
      }
    }

    @media (max-width: 1160px) {
      img {
        width: 80%;
      }
    }

    @media (max-width: 760px) {
      img {
        width: 90%;
      }
    }

    @media only screen and (max-device-width: 600px) {
      img {
        width: 90%;
      }
    }
  
    #week-food {
      width: 55%;
    }
  }  