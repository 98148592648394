.one-food-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    margin: auto auto auto auto;
    padding: 50px 0;
    box-sizing: border-box; 
    
    &::before {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url("../../assets/webp/options-menu.webp");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        opacity: 0.3; // Ajusta la opacidad según tus necesidades
        z-index: -1; // Coloca el pseudo-elemento detrás del contenido
      }
  
    h1 {
      text-align: center;
      margin-bottom: 20px;
    }
  
    h2 {
      margin-top: 20px;
    }
  
    p {
      margin: 10px 0;
      line-height: 1.6;
      text-align: justify;
    }
  
    ul {
      margin: 10px 0;
      padding-left: 20px;
  
      li {
        margin: 5px 0;
      }
    }
    #volver{
        width: 165px;
        margin: 20px auto 0px auto;
    }

    @media only screen and (max-device-width: 800px) {
      width: 90%;
      height: auto;
      margin-top: 75px;
    }

    @media only screen and (max-device-width: 1200px) {
      width: 70%;
      height: auto;
      margin-top: 75px;
    }
  }
  