.loading-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    z-index: 2;
    padding-top: 10%;
}

.spinner-border{
    width: 6rem !important; 
    height: 6rem !important;
}