.success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2{
        text-align: center;
    }
  
    p {
      font-size: 18px;
      text-align: center
    }

  }