.contact-form {
    max-width: 600px;
    margin: 10% auto;
    padding: 20px;
  
    h1 {
      text-align: center;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      div {
        margin-bottom: 15px;
  
        label {
          margin-bottom: 5px;
          display: block;
        }
  
        input,
        textarea {
          padding: 10px;
          font-size: 16px;
          width: 100%;
          box-sizing: border-box;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
  
        textarea {
          resize: vertical;
        }
      }
    }
  }
  