@import url('https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Signika Negative', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d1d1d1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #2997ff; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #62b3fe; 
}