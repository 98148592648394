$background: '../../assets/webp/recipe-image.webp';

.texto-container{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 95vh;
    width: 100%;
    background-image: url('#{$background}');
    background-repeat: repeat-y;
    background-size: contain;
    background-position: left;
}

.texto{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    background: rgba(255, 255, 255, 0.653);
    padding: 5vw;
}

.nombre-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nombre-container > h4{
    font-size: 2rem;
    letter-spacing: -.1rem;
    text-align: center;
}

.nombre-container > h2{
    font-size: 3.5rem;
    letter-spacing: -.1rem;
    font-weight: 700;
    text-align: center;
}

.ingredientes-container,.preparacion-container,.valor-container, .ingredientes-container > h4, .preparacion-container > h4, .preparacion-container > p {
    text-align: center;
}

.lista-ingredientes{
    display: flex;
    justify-content: center;
}

.preparacion-container > p{
    text-align: justify;
}

.buttons-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin-top: 5%;
}

.heart-icon {
    cursor: pointer;
    font-size: 2rem;
    user-select: none;
}

// DESKTOP
@media only screen and (min-width: 50px) and (max-width: 470px){

    .nombre-container > h4{
        font-size: -0.5rem;
        letter-spacing: -.1rem;
        text-align: center;
    }
    
    .nombre-container > h2{
        font-size: 2rem;
        letter-spacing: -.1rem;
        font-weight: 700;
        text-align: center;
    }

    .buttons-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .buttons-container > #crear{
        margin-bottom: 10px;
        min-width: 100px;
        padding: 0px;
    }

    .buttons-container > #volver{
        min-width: 100px;
        padding: 0px;
    }
}
/* Medium devices (768px and up) */
@media only screen and (min-width: 300px) and (max-width: 690px){
    .texto{
        width: 90%;
        padding: 15vw;
    }
    .buttons-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .buttons-container > #crear{
        margin-bottom: 10px;
    }
}