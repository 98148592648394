.authentication-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  

  &::before {    
    content: "";
    background-image: url('../../assets/webp/auth-fondo.webp');
        -webkit-background-size: contain;
        -moz-background-size: contain;
        -o-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.8;
  }
}