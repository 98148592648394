.sign-up-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  h2 {
    text-align: center;
    letter-spacing: -0.1rem;
    font-weight: 700;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
  }

  .anchor-init-sesion{
    text-decoration: underline;
    color: green;
  }

  .password-message{
    color: red;
    margin-bottom: 25px;
    text-align: center;
  }

  @media screen and (max-width: 600px) {
    padding: 0px 5%;
    margin-top: 25px;
  }
  @media screen and (max-width: 1000px) and (orientation: landscape) {
      margin-top: 220px;
      padding-bottom: 35px;
    
    .buttons-container {
      margin-bottom: 5%;
    }
  }
}