.generate-recipe-container{
    width: 100% !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 65px;
}

.mode-container{
    width: 300px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    margin-top: 5px;
    z-index: 2;
}

.easy-mode{
    display: flex;
    align-items: center;
    margin: 0px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 5px;
    width: 100px;
    height: 100px;
    text-align: center;
    letter-spacing: -.1rem;
    cursor: pointer;
    &:hover{
        text-shadow: rgb(60, 60, 60) 1px 0 10px;
    }
}

.complete-mode{
    display: flex;
    align-items: center;
    margin: 0px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 5px;
    width: 100px;
    height: 100px;
    justify-content: center;
    text-align: center;
    letter-spacing: -.1rem;
    cursor: pointer;
    &:hover{
        text-shadow: rgb(60, 60, 60) 1px 0 10px;
    }
}


