.easy-mode-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .easy-mode-title{
        max-width: 850px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 5vh 0px;

        & img{
            justify-content: center;
            min-width:55%;
        }

        & .text-content{
            width: 30%;
        }

        & .text-content > h2{
            font-size: 3rem;
            letter-spacing: -.1rem;
            font-weight: 700;
            margin-bottom: 30px;
            text-align: center;
        }
        @media only screen and (max-device-width: 600px) {
            flex-direction: column;
            .text-content{
                margin-top: 20px;
                width: 80%;
            }
        }
        @media only screen and (max-width: 880px){
            flex-direction: column;
            .text-content{
                margin-top: 20px;
                width: 50%;
            }
        }
    }

    .easy-options-container{
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 0 10vh 0;

        & button{
            width: 100%;
            height: 60px;
            margin-bottom: 10px;
            border: 2px solid rgb(0, 0, 0);
            border-radius: 5px;
            letter-spacing: -.07rem;
            font-weight: 600;
            color: rgb(0, 0, 0);
            background-color: rgb(255, 255, 255);
            text-decoration: none;
        }
    }
}



