.recipe-content{
display: flex;
flex-direction: column;
align-items: center;
}

.receta-favorita{
    list-style: none;
    a{
        text-decoration: none;
        cursor: pointer;
        color: inherit;
    }

    a:hover{
        text-decoration: none;
        cursor: pointer;
        color: inherit;
    }
    
}